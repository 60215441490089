//--------------------------------------------------
// Connecting rod implementation for verlet point masses
// Copyright Lewis Jones 2023
//--------------------------------------------------

import { Vector2D } from "math";
import MassConnector from "./MassConnector";
import PointMass from "./PointMass";

export default class Verlet2DRod extends MassConnector {
  constructor(
    readonly massA: PointMass,
    readonly massB: PointMass,
    readonly targetLength: number,
    readonly maxOnly: boolean = false,
  ) {
    super(false, massA, massB, targetLength, maxOnly);
  }

  protected ResolveDistance(positionOffset: Vector2D) {
    const correction = positionOffset.Divide(
      this.massA.massReciprocal + this.massB.massReciprocal,
    );
    this.massA.position = this.massA.position.Add(
      correction.Multiply(this.massA.massReciprocal),
    );
    this.massB.position = this.massB.position.Sub(
      correction.Multiply(this.massB.massReciprocal),
    );
  }
  protected ResolveVelocity(relativeVelocity: Vector2D) {
    const velocityCorrection = relativeVelocity.Divide(
      2 * (this.massA.massReciprocal + this.massB.massReciprocal),
    );
    this.massA.ApplyForce(velocityCorrection);
    this.massB.ApplyForce(velocityCorrection.Multiply(-1));
  }
}
