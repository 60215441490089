//--------------------------------------------------
// Simulation base class
// Copyright Lewis Jones 2023
//--------------------------------------------------

import { Rectangle, Vector2D } from "math";
import { black, grey } from "utils";

export default abstract class Applet {
  // Environment variables
  readonly drawingContext: CanvasRenderingContext2D | null; // = null;
  // Simulation variables
  simulationTimer: NodeJS.Timer | null = null;
  frameIndex: number = 0;
  iterationCount: number = 6;
  deltaTime: number = 0.025;
  deltaTimeSqr: number = this.deltaTime * this.deltaTime;
  deltaTimeReciprocal: number = 1 / this.deltaTime;
  maxSize: Vector2D = new Vector2D(10, 10);
  // Drawing variables
  bounds: Rectangle = new Rectangle(0, 0, 0, 0);
  mousePosition: Vector2D = new Vector2D(-100, -100);
  scale: number;
  scaleReciprocal: number;
  cursorSize: number;
  scaledCursorSize: number;

  constructor(readonly canvas: HTMLCanvasElement, deltaTime: number = 0.025) {
    this.SubscribeToEvents();
    this.drawingContext = this.canvas.getContext("2d");
    this.InitializeDrawingContext();

    this.deltaTime = deltaTime;
    this.deltaTimeSqr = this.deltaTime * this.deltaTime;
    this.deltaTimeReciprocal = 1 / this.deltaTime;

    this.scale = 1;
    this.scaleReciprocal = 1;
    this.cursorSize = 5;
    this.scaledCursorSize = this.cursorSize * this.scaleReciprocal;

    this.HandleResize();
  }
  Dispose() {
    this.UnsubscribeFromEvents();
    this.ResetIntervalTimer();
  }

  SubscribeToEvents() {
    this.canvas.addEventListener(
      "mousemove",
      this.HandleMouseMove.bind(this),
      false,
    );
    this.canvas.addEventListener(
      "mousedown",
      this.HandleMouseDown.bind(this),
      false,
    );
    this.canvas.addEventListener(
      "mouseup",
      this.HandleMouseUp.bind(this),
      false,
    );
    window.addEventListener("resize", this.HandleResize.bind(this), false);
  }

  UnsubscribeFromEvents() {
    this.canvas.removeEventListener(
      "mousemove",
      this.HandleMouseMove.bind(this),
      false,
    );
    this.canvas.removeEventListener(
      "mousedown",
      this.HandleMouseDown.bind(this),
      false,
    );
    this.canvas.removeEventListener(
      "mouseup",
      this.HandleMouseUp.bind(this),
      false,
    );
    window.removeEventListener("resize", this.HandleResize.bind(this), false);
  }

  SetView(scale: number, offset: Vector2D) {
    if (!this.drawingContext) {
      return;
    }
    this.drawingContext.setTransform(scale, 0, 0, scale, offset.x, offset.y);
    this.scale = scale;
    this.scaleReciprocal = 1 / scale;
  }
  SetViewScale(scale: number) {
    if (scale === 0) {
      return;
    }
    this.SetView(scale, this.GetViewOffset());
  }
  SetViewOffset(offset: Vector2D) {
    this.SetView(this.GetViewScale(), offset);
  }

  GetViewScale(): number {
    return this.drawingContext?.getTransform().a ?? 1;
  }
  GetViewOffset(): Vector2D {
    return new Vector2D(
      this.drawingContext?.getTransform().e ?? 0,
      this.drawingContext?.getTransform().f ?? 0,
    );
  }

  /* eslint-disable class-methods-use-this, @typescript-eslint/no-unused-vars */
  protected HandleMouseDown(e: MouseEvent) {}
  protected HandleMouseUp(e: MouseEvent) {}
  protected HandleMouseMove(e: MouseEvent) {
    const clientRect = (e.target as HTMLElement).getBoundingClientRect();
    this.mousePosition.x = (e.clientX - clientRect.left) * this.scaleReciprocal;
    this.mousePosition.y = (e.clientY - clientRect.top) * this.scaleReciprocal;
  }
  /* eslint-enable class-methods-use-this, @typescript-eslint/no-unused-vars */

  HandleResize() {
    if (!this.canvas) {
      this.bounds = new Rectangle(0, 0, 0, 0);
      return;
    }
    // Ensure the canvas size responsively matches the container size
    this.canvas.width = this.canvas.parentElement?.clientWidth ?? 0;
    this.canvas.height = this.canvas.parentElement?.clientHeight ?? 0;
    if (this.canvas.width < 0 || this.canvas.height < 0) {
      this.bounds = new Rectangle(0, 0, 0, 0);
      return;
    }
    this.SetView(
      new Vector2D(this.canvas.width, this.canvas.height)
        .VectorDivide(this.maxSize)
        .MaxComponent(),
      new Vector2D(0, 0),
    );
    this.bounds = new Rectangle(
      0,
      0,
      this.canvas.width * this.scaleReciprocal,
      this.canvas.height * this.scaleReciprocal,
    );
    this.scaledCursorSize = this.cursorSize * this.scaleReciprocal;

    this.InitializeSimulation();
  }

  private ResetIntervalTimer() {
    if (this.simulationTimer) {
      clearInterval(this.simulationTimer);
    }
  }
  private SetIntervalTimer() {
    this.ResetIntervalTimer();
    this.simulationTimer = setInterval(
      this.Update.bind(this),
      this.deltaTimeReciprocal,
    );
  }

  Initialize() {
    this.frameIndex = 0;
    this.SetIntervalTimer();
    this.HandleResize();
    this.Draw();
  }
  /* eslint-disable class-methods-use-this */
  InitializeSimulation() {}
  /* eslint-enable class-methods-use-this */
  InitializeDrawingContext(): void {
    if (!this.drawingContext) {
      return;
    }
    this.drawingContext.font = `${50 * this.scaleReciprocal}px Arial`;
    this.drawingContext.strokeStyle = black;
    this.drawingContext.fillStyle = black;
    this.drawingContext.lineWidth = Math.min(0.05, 2.5 * this.scaleReciprocal);
    this.drawingContext.lineCap = "butt";
    this.drawingContext.lineJoin = "round";
  }

  Update() {
    // Check that there's a valid display area
    if (
      this.bounds &&
      this.bounds.right > this.bounds.left &&
      this.bounds.bottom > this.bounds.top
    ) {
      // Update the simulation
      this.UpdateSimulation();
      // Draw everything
      this.Draw();
    }
  }
  UpdateSimulation() {
    this.frameIndex += 1;
  }

  Draw() {
    if (!this.canvas || !this.drawingContext) {
      return;
    }
    // Clear the canvas
    this.drawingContext.clearRect(0, 0, this.canvas.width, this.canvas.height);
    // Configure default drawing settings
    this.InitializeDrawingContext();
    // Draw a ruler
    this.DrawRuler();
    // Draw the simulation
    this.DrawSimulation();
    // Draw the cursor
    this.DrawCursor();
  }
  DrawSimulation() {
    if (!this.drawingContext) {
      return;
    }
    this.drawingContext.save();
    this.drawingContext.strokeStyle = black;
    this.drawingContext.strokeRect(
      this.bounds.left,
      this.bounds.top,
      this.bounds.size.x,
      this.bounds.size.y,
    );
    this.drawingContext.restore();
  }
  DrawCursor() {
    if (!this.drawingContext) {
      return;
    }
    this.drawingContext.save();
    this.drawingContext.strokeStyle = black;
    this.drawingContext.globalAlpha = 0.2;
    this.drawingContext.beginPath();
    this.drawingContext.arc(
      this.mousePosition.x,
      this.mousePosition.y,
      this.scaledCursorSize,
      0,
      2 * Math.PI,
    );
    this.drawingContext.stroke();
    this.drawingContext.restore();
  }
  DrawRuler() {
    if (!this.drawingContext) {
      return;
    }
    this.drawingContext.save();
    this.drawingContext.strokeStyle = grey;
    this.drawingContext.beginPath();
    this.drawingContext.moveTo(
      20 * this.scaleReciprocal,
      this.bounds.bottom - 20 * this.scaleReciprocal,
    );
    this.drawingContext.lineTo(
      20 * this.scaleReciprocal,
      this.bounds.bottom - 20 * this.scaleReciprocal - 1,
    );
    this.drawingContext.stroke();
    this.drawingContext.fillStyle = grey;
    this.drawingContext.fillText(
      "1m",
      30 * this.scaleReciprocal,
      this.bounds.bottom - 20 * this.scaleReciprocal,
    );
    this.drawingContext.restore();
  }
}
