import { MutableRefObject, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import DetailSidebar from "./DetailSidebar";
import EntryModal from "./EntryModal";
import getEntries from "./getEntries";
import GridPane from "./GridPane";
import PortfolioEntry from "./interfaces/PortfolioEntry";
import NavSidebar from "./NavSidebar";

const entries = getEntries();

function HomeLayout() {
  const entryModalRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [modalEntry, setModalEntry] = useState<PortfolioEntry | null>(null);

  useOnClickOutside(entryModalRef, () => setModalEntry(null));

  return (
    <>
      <NavSidebar />
      <GridPane
        entries={entries}
        onClickEntry={(_, entry) => setModalEntry(entry)}
        interceptScroll={!modalEntry}
      />
      <DetailSidebar />
      <EntryModal ref={entryModalRef} entry={modalEntry} />
    </>
  );
}

export default HomeLayout;
