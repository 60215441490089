import rawEntries from "./entries";
import PortfolioEntry from "./interfaces/PortfolioEntry";

const imgPlaceholders = [
  "/img/entries/PlaceholderAspect1To1.png",
  "/img/entries/PlaceholderAspect3To2.png",
  "/img/entries/PlaceholderAspect4To3.png",
  "/img/entries/PlaceholderAspect16To9.png",
];

function getEntries(): Array<PortfolioEntry> {
  const dev = process.env.NODE_ENV === "development";
  const entries = rawEntries
    .filter(
      (entry) => !!entry.title && !!entry.brief && (!!entry.imageURL || dev),
    )
    .map((entry, index) => ({
      ...entry,
      startDate: entry.startDate ? new Date(entry.startDate) : new Date(),
      endDate: entry.endDate ? new Date(entry.endDate) : new Date(),
      description: entry.description ?? entry.brief,
      imageURL:
        entry.imageURL ?? imgPlaceholders[index % imgPlaceholders.length],
    }));
  return entries;
}

export default getEntries;
