//--------------------------------------------------
// Circular mass implementation
// Copyright Lewis Jones 2023
//--------------------------------------------------

import { Rectangle, Vector2D } from "math";
import PointMass from "./PointMass";

export default class CircleMass extends PointMass {
  radius: number;

  constructor(position: Vector2D, mass: number, radius: number) {
    super(position, mass);
    this.radius = radius;
  }

  ResolveBoundsCollisions(bounds: Rectangle) {
    super.ResolveBoundsCollisions(bounds.Expand(-this.radius));
  }
  ResolveMassCollision(otherMass: PointMass) {
    const relativePosition = otherMass.position.Sub(this.position);
    const distanceSqr = relativePosition.LengthSqr();
    let requiredDistance = this.radius;
    // If the other mass is also a Circle
    if (otherMass instanceof CircleMass) {
      requiredDistance += (otherMass as CircleMass).radius;
    }
    if (distanceSqr < requiredDistance * requiredDistance) {
      const distance = Math.sqrt(distanceSqr);
      if (this.massReciprocal + otherMass.massReciprocal > 0) {
        const correction = relativePosition
          .Multiply(requiredDistance / distance)
          .Sub(relativePosition)
          .Divide(this.massReciprocal + otherMass.massReciprocal);
        // Correct relative positions
        this.position = this.position.Sub(
          correction.Multiply(this.massReciprocal),
        );
        otherMass.SetPosition(
          otherMass.position.Add(correction.Multiply(otherMass.massReciprocal)),
        );
      }
    }
  }
}
