//--------------------------------------------------
// Point mass implementation
// Copyright Lewis Jones 2023
//--------------------------------------------------

import { Rectangle, Vector2D } from "math";

export default class PointMass {
  position: Vector2D;
  lastPosition: Vector2D;
  force: Vector2D;
  mass: number;
  massReciprocal: number;
  massStored: number; // Mass stored for when the node is unpinned

  constructor(position: Vector2D, mass: number) {
    if (mass <= 0) {
      throw new Error(
        `Cannot initialize mass; mass property must be positive (mass = ${mass})`,
      );
    }
    this.position = position;
    this.lastPosition = this.position.Copy();
    this.force = new Vector2D(0, 0); // Force
    this.mass = mass;
    this.massReciprocal = 0;
    this.SetMass(mass);
    this.massStored = 0;
  }

  SetMass(mass: number) {
    this.mass = mass;
    this.massReciprocal = 0;
    if (this.mass > 0) {
      this.massReciprocal = 1 / this.mass;
    }
  }

  SetPosition(newPosition: Vector2D) {
    this.position = newPosition;
  }
  SetPositionPreserveVelocity(newPosition: Vector2D) {
    const delta = newPosition.Sub(this.position);
    this.position = this.position.Add(delta);
    this.lastPosition = this.lastPosition.Add(delta);
  }

  Update(deltaTimeSqr: number) {
    const pTemp = new Vector2D(this.position.x, this.position.y);
    this.position = this.position
      .Add(this.position.Sub(this.lastPosition))
      .Add(this.force.Multiply(0.5 * deltaTimeSqr * this.massReciprocal));
    this.lastPosition = new Vector2D(pTemp.x, pTemp.y);
    this.force = new Vector2D(0, 0);
  }

  ApplyForce(force: Vector2D) {
    this.force = this.force.Add(force);
  }

  ResolveBoundsCollisions(bounds: Rectangle) {
    // Horizontal bound constraints
    if (this.position.x < bounds.left) {
      this.position.x = bounds.left;
      this.lastPosition.x = this.position.x;
    } else if (this.position.x > bounds.right) {
      this.position.x = bounds.right;
      this.lastPosition.x = this.position.x;
    }
    // Vertical bound constraints
    if (this.position.y < bounds.top) {
      this.position.y = bounds.top;
      this.lastPosition.y = this.position.y;
    } else if (this.position.y > bounds.bottom) {
      this.position.y = bounds.bottom;
      this.lastPosition.y = this.position.y;
    }
  }

  GetVelocity(deltaTimeReciprocal: number) {
    return this.lastPosition.Sub(this.position).Multiply(deltaTimeReciprocal);
  }

  IsPinned() {
    return this.massStored !== 0;
  }
  TogglePinned() {
    if (!this.IsPinned()) {
      // Mass is not pinned: pin it
      this.massStored = this.mass;
      this.mass = 0;
      this.massReciprocal = 0;
      this.lastPosition = this.position;
    } else {
      // Mass is already pinned: unpin it
      this.SetMass(this.massStored);
      this.massStored = 0;
    }
  }
  SetPinned(pin: boolean) {
    if (this.IsPinned()) {
      if (!pin) {
        // Mass is pinned but should not be: toggle
        this.TogglePinned();
      }
    } else if (pin) {
      // Mass is not pinned but should be: toggle
      this.TogglePinned();
    }
  }
}
