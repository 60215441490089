import { forwardRef, Ref } from "react";
import PortfolioEntry from "./interfaces/PortfolioEntry";
import styles from "./Modal.module.css";

function EntryModal(
  { entry }: { entry: PortfolioEntry | null },
  ref: Ref<HTMLDivElement>,
) {
  if (!entry) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.modal}>
        <header>{entry.title}</header>
        <div>{entry.description}</div>
      </div>
    </div>
  );
}

export default forwardRef(EntryModal);
