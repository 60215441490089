//--------------------------------------------------
// Connecting spring implementation for verlet point masses
// Copyright Lewis Jones 2023
//--------------------------------------------------

import { Vector2D } from "math";
import MassConnector from "./MassConnector";
import PointMass from "./PointMass";

export default class Spring extends MassConnector {
  constructor(
    readonly massA: PointMass,
    readonly massB: PointMass,
    readonly targetLength: number,
    readonly springConstant: number,
    readonly dampingFactor: number = 0,
    readonly maxOnly: boolean = false,
  ) {
    if (springConstant < 0) {
      throw new Error(
        `Cannot initialize balloon; must have positive spring constant (springConstant = ${springConstant})`,
      );
    }
    if (dampingFactor < 0) {
      throw new Error(
        `Cannot initialize balloon; must have positive damping (dampingFactor = ${dampingFactor})`,
      );
    }
    super(true, massA, massB, targetLength, maxOnly);
  }

  protected ResolveDistance(positionOffset: Vector2D) {
    this.massA.ApplyForce(positionOffset.Multiply(this.springConstant));
    this.massB.ApplyForce(positionOffset.Multiply(-this.springConstant));
  }
  protected ResolveVelocity(relativeVelocity: Vector2D) {
    this.massA.ApplyForce(relativeVelocity.Multiply(this.dampingFactor));
    this.massB.ApplyForce(relativeVelocity.Multiply(-this.dampingFactor));
  }
}
