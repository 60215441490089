//--------------------------------------------------
// Bounds implementation
// Copyright Lewis Jones 2023
//--------------------------------------------------

import Vector2D from "./Vector2D";

// Note that this requires x+ to be right and y+ to be down (as in HTML layouts)
export default class Rectangle {
  readonly size: Vector2D;
  readonly centre: Vector2D;
  readonly leftTop: Vector2D;
  readonly rightTop: Vector2D;
  readonly leftBottom: Vector2D;
  readonly rightBottom: Vector2D;

  constructor(
    readonly left: number,
    readonly top: number,
    readonly right: number,
    readonly bottom: number,
  ) {
    this.size = new Vector2D(this.right - this.left, this.bottom - this.top);
    this.leftTop = new Vector2D(this.left, this.top);
    this.rightTop = new Vector2D(this.right, this.top);
    this.leftBottom = new Vector2D(this.left, this.bottom);
    this.rightBottom = new Vector2D(this.right, this.bottom);
    this.centre = this.GetInterpolatedPoint(0.5, 0.5);
  }

  GetInterpolatedPoint(xFraction: number, yFraction: number) {
    return new Vector2D(
      this.GetInterpolatedX(xFraction),
      this.GetInterpolatedY(yFraction),
    );
  }
  GetInterpolatedX(fraction: number) {
    return this.left * (1 - fraction) + this.right * fraction;
  }
  GetInterpolatedY(fraction: number) {
    return this.top * (1 - fraction) + this.bottom * fraction;
  }

  Expand(expansionAmount: number) {
    return new Rectangle(
      this.left - expansionAmount,
      this.top - expansionAmount,
      this.right + expansionAmount,
      this.bottom + expansionAmount,
    );
  }
}
