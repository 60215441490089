export function AngleToTwoPiRange(inputAngle: number): number {
  if (inputAngle >= 2 * Math.PI) {
    return inputAngle % (2 * Math.PI);
  }
  if (inputAngle < 0) {
    if (inputAngle < -2 * Math.PI) {
      return 2 * Math.PI - (-inputAngle % (2 * Math.PI));
    }
    return 2 * Math.PI + inputAngle;
  }
  return inputAngle;
}

export function AngleToPlusMinusPiRange(inputAngle: number): number {
  if (inputAngle > Math.PI) {
    const angle = ((inputAngle + Math.PI) % (2 * Math.PI)) - Math.PI;
    return angle === -Math.PI ? angle + 2 * Math.PI : angle;
  }
  if (inputAngle <= -Math.PI) {
    return -((-inputAngle + Math.PI) % (2 * Math.PI)) + Math.PI;
  }
  return inputAngle;
}
