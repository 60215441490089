import { MutableRefObject, useRef } from "react";
import { useEventListener } from "usehooks-ts";
import styles from "./Grid.module.css";
import PortfolioEntry from "./interfaces/PortfolioEntry";

function GridItem({
  entry,
  onClickEntry,
}: {
  entry: PortfolioEntry;
  onClickEntry: (
    e: MouseEvent,
    entry: PortfolioEntry,
    element: HTMLElement | null,
  ) => void;
}) {
  const thisItemRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  useEventListener(
    "click",
    (e) => onClickEntry(e, entry, thisItemRef.current),
    thisItemRef,
  );

  return (
    <div ref={thisItemRef} className={styles.item} key={entry.id.toString()}>
      <img src={entry.imageURL} alt={entry.description} />
      <div>
        <header>{entry.title}</header>
        <div>{entry.brief}</div>
      </div>
    </div>
  );
}

export default GridItem;
