import { MutableRefObject, useRef } from "react";
import { useEventListener } from "usehooks-ts";
import styles from "./Grid.module.css";
import GridItem from "./GridItem";
import PortfolioEntry from "./interfaces/PortfolioEntry";

function GridPane({
  entries,
  onClickEntry,
  interceptScroll,
}: {
  entries: PortfolioEntry[];
  onClickEntry: (
    e: MouseEvent,
    entry: PortfolioEntry,
    element: HTMLElement | null,
  ) => void;
  interceptScroll: boolean;
}) {
  const thisGridPaneRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const gridItems = entries.map((entry: PortfolioEntry) => (
    <GridItem key={entry.id} entry={entry} onClickEntry={onClickEntry} />
  ));

  function handleWheel(e: Event) {
    if (!interceptScroll) {
      return;
    }
    const grid = thisGridPaneRef.current;
    if (!grid) {
      return;
    }
    const wheelEvent = e as WheelEvent;
    if (!wheelEvent) {
      return;
    }
    grid.scrollTop += wheelEvent.deltaY;
    if (
      grid.scrollTop > 0 &&
      grid.scrollTop + grid.offsetHeight < grid.scrollHeight
    ) {
      e.preventDefault();
    }
  }

  useEventListener("wheel", handleWheel, undefined, { passive: false });

  return (
    <div className={styles.container} ref={thisGridPaneRef}>
      <div className={styles.grid}>{gridItems}</div>
    </div>
  );
}

export default GridPane;
