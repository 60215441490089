//--------------------------------------------------
// Balloon implementation for verlet point masses connected in a loop
// Copyright Lewis Jones 2023
//--------------------------------------------------

import Constraint from "./Constraint";
import MassConnector from "./MassConnector";

export default class Balloon extends Constraint {
  constructor(
    readonly edges: MassConnector[],
    readonly targetArea: number,
    readonly pressureFactor: number,
  ) {
    if (edges.length < 3) {
      throw new Error(
        `Cannot create balloon; must consist of at least 3 edges (edges.length = ${edges.length})`,
      );
    }
    for (let r = 0; r < edges.length; r += 1) {
      if (edges[r].massB !== edges[(r + 1) % edges.length].massA) {
        throw new Error(
          "Cannot create balloon; rods must form a continuous loop",
        );
      }
    }
    if (targetArea <= 0) {
      throw new Error(
        `Cannot create balloon; target area must be positive (targetArea = ${targetArea}`,
      );
    }
    if (pressureFactor <= 0) {
      throw new Error(
        `Cannot create balloon; pressure factor must be positive (pressureFactor = ${pressureFactor}`,
      );
    }

    super(true);
  }

  Resolve() {
    const { area, circumference } = this.GetCurrentProperties();
    const pressurePerLength =
      ((this.targetArea - area) * this.pressureFactor) / circumference;
    for (let e = this.edges.length - 1; e >= 0; e -= 1) {
      const force = this.edges[e]
        .SpanVector()
        .PerpLeft()
        .Multiply(0.5 * pressurePerLength);
      this.edges[e].massA.ApplyForce(force);
      this.edges[e].massB.ApplyForce(force);
    }
  }

  protected GetCurrentProperties(): {
    area: number;
    circumference: number;
    lengths: number[];
  } {
    // Calculate the length of each edge
    const lengths: number[] = this.edges.map((edge) =>
      edge.massB.position.Sub(edge.massA.position).Length(),
    );
    // Sum the edge lengths to get the circumference
    const circumference: number = lengths.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0,
    );
    // Calculate the area of the polygon
    const area =
      0.5 *
      this.edges.reduce(
        (previousValue: number, currentValue: MassConnector) =>
          previousValue +
          currentValue.massA.position.Cross(currentValue.massB.position),
        0,
      );
    return { area, circumference, lengths };
  }
}
