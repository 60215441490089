import { MutableRefObject, useEffect, useRef } from "react";
import { useDocumentTitle } from "usehooks-ts";
import Applet from "./applets/Applet";

import { ConcreteApplet } from "./applets/AppletNameMappings";

function AppletContainer({
  HostedApplet,
  appletTitle,
}: {
  HostedApplet: ConcreteApplet;
  appletTitle: string;
}) {
  const canvasRef: MutableRefObject<HTMLCanvasElement | null> = useRef(null);

  const applet: MutableRefObject<Applet | null> = useRef(null);
  useDocumentTitle(`Lewis Antony Jones | ${appletTitle}`);

  useEffect(() => {
    if (!canvasRef.current) {
      applet.current?.Dispose();
      applet.current = null;
      return;
    }
    if (!HostedApplet) {
      return;
    }
    if (!applet.current) {
      applet.current = new HostedApplet(canvasRef.current);
      applet.current.Initialize();
    }
  }, [canvasRef, HostedApplet]);

  return (
    <>
      <canvas ref={canvasRef} />
    </>
  );
}

export default AppletContainer;
