import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import styles from "./App.module.css";
import AppletContainer from "./AppletContainer";
import AppletDirectory from "./AppletDirectory";
import HomeLayout from "./HomeLayout";
import { appletSlugMapping } from "./applets/AppletNameMappings";

function App() {
  return (
    <div className={styles.app}>
      <BrowserRouter basename="">
        <Routes>
          <Route path="/" element={<HomeLayout />} />
          {Object.keys(appletSlugMapping).map((slug) => (
            <Route
              key={slug}
              path={`/app/${slug}`}
              element={
                <AppletContainer
                  HostedApplet={appletSlugMapping[slug].Applet}
                  appletTitle={appletSlugMapping[slug].title}
                />
              }
            />
          ))}
          <Route path="/app/*" element={<Navigate replace to="../app" />} />
          <Route path="/app" element={<AppletDirectory />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
