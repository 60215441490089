//--------------------------------------------------
// Base class for implementation of constraints acting on verlet physics entities
// Copyright Lewis Jones 2023
//--------------------------------------------------

export default class Constraint {
  constructor(readonly forceBased: boolean) {}
  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
  Resolve(deltaTimeReciprocal: number) {}
}
