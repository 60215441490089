//--------------------------------------------------
// A verlet physics simulator
// Copyright Lewis Jones 2023
//--------------------------------------------------

import { Rectangle, Vector2D } from "math";
import { black, grey } from "utils";
import {
  Balloon,
  CircleMass,
  Constraint,
  MassConnector,
  PointMass,
  Rod,
  Spring,
} from "verlet2d";
import Applet from "./Applet";

export default class Verlet2DPhysics extends Applet {
  // Simulation variables
  masses: PointMass[] = [];
  circleMasses: CircleMass[] = [];
  constraints: Constraint[] = [];
  drawFunctions: (() => void)[] = [];
  selectDist: number = 0.2;
  selectDistSqr: number = this.selectDist * this.selectDist;
  selectDistScaled: number = this.selectDist * this.scaleReciprocal;
  massDrawRadius: number = 5;
  massDrawRadiusScaled: number = this.cursorSize * this.scaleReciprocal;
  selectedMassIndex = -1;

  constructor(canvas: HTMLCanvasElement, deltaTime: number = 0.025) {
    super(canvas, deltaTime);
  }

  // Input
  HandleMouseDown(e: MouseEvent) {
    super.HandleMouseDown(e);

    if (e.button === 0) {
      // Find the closest mass
      this.selectedMassIndex = -1;
      let closestDist2 = this.selectDistSqr;
      let currentDist2 = 0;
      for (let m = this.masses.length - 1; m >= 0; m -= 1) {
        currentDist2 = this.masses[m].position.DistanceSqr(this.mousePosition);
        if (currentDist2 < closestDist2) {
          closestDist2 = currentDist2;
          this.selectedMassIndex = m;
        }
      }
      if (this.selectedMassIndex > -1 && e.shiftKey) {
        // If shift is pressed
        // Toggle pinning instead of selecting
        this.masses[this.selectedMassIndex].TogglePinned();
        this.selectedMassIndex = -1;
      }
    }
  }
  HandleMouseUp(e: MouseEvent) {
    super.HandleMouseUp(e);

    if (e.button === 0) {
      this.selectedMassIndex = -1;
    }
  }

  HandleResize() {
    super.HandleResize();

    this.selectDistScaled = this.selectDist * this.scaleReciprocal;
    this.massDrawRadiusScaled = this.massDrawRadius * this.scaleReciprocal;
  }

  InvalidateDrawFunctions() {
    this.drawFunctions = [];
  }

  AddConstraint(constraint: Constraint) {
    this.constraints[this.constraints.length] = constraint;
    this.InvalidateDrawFunctions();
  }

  // Initialization
  InitializeSimulation() {
    super.InitializeSimulation();

    this.masses = [];
    this.circleMasses = [];
    this.constraints = [];

    this.InitializeRope(
      this.bounds.GetInterpolatedPoint(0.25, 0.02),
      this.bounds.GetInterpolatedPoint(0.4, 0.45),
      15,
      2,
    );

    const clothRect = new Rectangle(
      this.bounds.GetInterpolatedX(0.45),
      this.bounds.GetInterpolatedY(0.02),
      this.bounds.GetInterpolatedX(0.95),
      this.bounds.GetInterpolatedY(0.4),
    );
    const maxLinks = 16;
    const divisor = clothRect.size.Divide(maxLinks).MaxComponent();
    const linkCounts = clothRect.size.Divide(divisor);
    linkCounts.x = Math.ceil(linkCounts.x);
    linkCounts.y = Math.ceil(linkCounts.y);
    this.InitializeCloth(clothRect, linkCounts, 1);

    this.InitializeRectangle(
      new Rectangle(
        this.bounds.centre.x - 0.5,
        this.bounds.centre.y - 0.5,
        this.bounds.centre.x + 0.5,
        this.bounds.centre.y + 0.5,
      ),
      1,
    );

    let point = this.bounds.GetInterpolatedPoint(0.8, 0.6);
    this.InitializeBalloon(point, 1, 20, 1, 100, 100, 0.5);

    point = this.bounds.GetInterpolatedPoint(0.3, 0.1);
    this.masses[this.masses.length] = new CircleMass(point, 2, 0.25);
    point = this.bounds.GetInterpolatedPoint(0.55, 0.75);
    this.masses[this.masses.length] = new CircleMass(point, 15, 1);

    this.RandomizeMassPositions(0.1);

    const length = 0.5;
    const mass = 1;
    const k = 10;
    point = this.bounds.GetInterpolatedPoint(0.1, 0.6);
    this.InitializeDemoSpring(point, length, mass, k, 0);
    point = this.bounds.GetInterpolatedPoint(0.15, 0.6);
    this.InitializeDemoSpring(point, length, mass, k, 1);
    point = this.bounds.GetInterpolatedPoint(0.2, 0.6);
    const criticalDamping = 2 * Math.sqrt(k * mass);
    this.InitializeDemoSpring(point, length, mass, k, criticalDamping);
  }

  InitializeRope(
    a: Vector2D,
    b: Vector2D,
    linkCount: number,
    totalMass: number,
    pinA: boolean = true,
    pinB: boolean = false,
    lengthOverride: number | null = null,
  ) {
    if (linkCount < 1) {
      throw new Error(
        `Cannot initialize rope; must have links (linkCount = ${linkCount})`,
      );
    }
    if (totalMass <= 0) {
      throw new Error(
        `Cannot initialize rope; must have positive mass (totalMass = ${totalMass})`,
      );
    }
    const length = lengthOverride ?? a.Distance(b);
    if (length <= 0) {
      throw new Error(
        `Cannot initialize rope; must have positive length (length = ${length})`,
      );
    }
    const baseMassIndex = this.masses.length;
    const segment = b.Sub(a).Divide(linkCount);
    let position = a;
    const rodLength = length / linkCount;
    const individualMass = totalMass / (linkCount + 1);
    for (let m = 0; m < linkCount + 1; m += 1) {
      this.masses[baseMassIndex + m] = new PointMass(position, individualMass);
      position = position.Add(segment);
    }
    for (let c = 0; c < linkCount; c += 1) {
      this.AddConstraint(
        new Rod(
          this.masses[baseMassIndex + c],
          this.masses[baseMassIndex + c + 1],
          rodLength,
        ),
      );
    }
    // Pin (or don't pin) the end masses in place as requested
    this.masses[baseMassIndex].SetPinned(pinA);
    this.masses[baseMassIndex + linkCount].SetPinned(pinB);
  }

  InitializeCloth(rect: Rectangle, linkCounts: Vector2D, totalMass: number) {
    if (linkCounts.x < 1 || linkCounts.y < 1) {
      throw new Error(
        `Cannot initialize cloth; must have links (linkCount = ${linkCounts.x}, ${linkCounts.y})`,
      );
    }
    if (rect.size.x <= 0 && rect.size.y <= 0) {
      throw new Error(
        `Cannot initialize cloth; must have positive size (size = ${rect.size.x}, ${rect.size.y})`,
      );
    }
    if (totalMass <= 0) {
      throw new Error(
        `Cannot initialize cloth; must have positive mass (totalMass = ${totalMass})`,
      );
    }
    // Prepare the construction variables
    const baseMassIndex: number = this.masses.length;
    const rodLength: Vector2D = rect.size.VectorDivide(linkCounts);
    const individualMass: number =
      totalMass / ((linkCounts.x + 1) * (linkCounts.y + 1));
    // Place masses in a grid
    let subBaseMassIndex;
    for (let j = 0; j <= linkCounts.y; j += 1) {
      subBaseMassIndex = baseMassIndex + j * (linkCounts.x + 1);
      for (let i = 0; i <= linkCounts.x; i += 1) {
        this.masses[subBaseMassIndex + i] = new PointMass(
          new Vector2D(rect.left + rodLength.x * i, rect.top + rodLength.y * j),
          individualMass,
        );
      }
    }
    for (let j = 0; j <= linkCounts.y; j += 1) {
      subBaseMassIndex = baseMassIndex + j * (linkCounts.x + 1);
      for (let i = 0; i < linkCounts.x; i += 1) {
        // Link masses horizontally
        this.AddConstraint(
          new Rod(
            this.masses[subBaseMassIndex + i],
            this.masses[subBaseMassIndex + i + 1],
            rodLength.x,
            true,
          ),
        );
      }
    }
    // Link masses vertically
    for (let j = 0; j < linkCounts.y; j += 1) {
      subBaseMassIndex = baseMassIndex + j * (linkCounts.x + 1);
      for (let i = 0; i <= linkCounts.x; i += 1) {
        // Link masses horizontally
        this.AddConstraint(
          new Rod(
            this.masses[subBaseMassIndex + i],
            this.masses[subBaseMassIndex + i + linkCounts.x + 1],
            rodLength.y,
            true,
          ),
        );
      }
    }
    // Pin some of the top row masses
    this.masses[baseMassIndex].TogglePinned();
    this.masses[
      baseMassIndex + Math.round(linkCounts.x * 0.333)
    ].TogglePinned();
    this.masses[
      baseMassIndex + Math.round(linkCounts.x * 0.667)
    ].TogglePinned();
    this.masses[baseMassIndex + linkCounts.x].TogglePinned();
  }

  InitializeRectangle(rect: Rectangle, totalMass: number) {
    if (rect.size.x <= 0 && rect.size.y <= 0) {
      throw new Error(
        `Cannot initialize rectangle; must have positive size (size = ${rect.size.x}, ${rect.size.y})`,
      );
    }
    if (totalMass <= 0) {
      throw new Error(
        `Cannot initialize rectangle; must have positive mass (totalMass = ${totalMass})`,
      );
    }
    const baseMassIndex = this.masses.length;
    const cornerMass = totalMass * 0.25;
    const diagonalLength = rect.size.Length();

    this.masses[baseMassIndex] = new PointMass(rect.leftTop, cornerMass);
    this.masses[baseMassIndex + 1] = new PointMass(rect.rightTop, cornerMass);
    this.masses[baseMassIndex + 2] = new PointMass(
      rect.rightBottom,
      cornerMass,
    );
    this.masses[baseMassIndex + 3] = new PointMass(rect.leftBottom, cornerMass);

    this.AddConstraint(
      new Rod(
        this.masses[baseMassIndex],
        this.masses[baseMassIndex + 1],
        rect.size.x,
      ),
    );
    this.AddConstraint(
      new Rod(
        this.masses[baseMassIndex + 1],
        this.masses[baseMassIndex + 2],
        rect.size.y,
      ),
    );
    this.AddConstraint(
      new Rod(
        this.masses[baseMassIndex + 2],
        this.masses[baseMassIndex + 3],
        rect.size.x,
      ),
    );
    this.AddConstraint(
      new Rod(
        this.masses[baseMassIndex + 3],
        this.masses[baseMassIndex],
        rect.size.y,
      ),
    );
    this.AddConstraint(
      new Rod(
        this.masses[baseMassIndex],
        this.masses[baseMassIndex + 2],
        diagonalLength,
      ),
    );
    this.AddConstraint(
      new Rod(
        this.masses[baseMassIndex + 1],
        this.masses[baseMassIndex + 3],
        diagonalLength,
      ),
    );
  }

  InitializeDemoSpring(
    anchorPosition: Vector2D,
    length: number,
    mass: number,
    springConstant: number,
    damping: number = 0,
  ) {
    if (length <= 0) {
      throw new Error(
        `Cannot initialize demo spring; must have positive length (length = ${length})`,
      );
    }
    if (mass <= 0) {
      throw new Error(
        `Cannot initialize demo spring; must have positive mass (mass = ${mass})`,
      );
    }
    if (damping < 0) {
      throw new Error(
        `Cannot initialize demo spring; must have positive damping (damping = ${damping})`,
      );
    }
    const anchor = new PointMass(anchorPosition, mass);
    anchor.SetPinned(true);
    const bob = new CircleMass(
      anchorPosition.Add(new Vector2D(0, length * 2)),
      mass,
      Math.sqrt(mass) * 0.1,
    );
    this.AddConstraint(
      new Spring(anchor, bob, length, springConstant, damping),
    );
    this.masses[this.masses.length] = anchor;
    this.masses[this.masses.length] = bob;
  }

  InitializeBalloon(
    centre: Vector2D,
    radius: number,
    linkCount: number,
    totalMass: number,
    pressureFactor: number,
    springConstant: number,
    dampingFactor: number = 0,
  ) {
    if (radius <= 0) {
      throw new Error(
        `Cannot initialize balloon; must have positive radius (radius = ${radius})`,
      );
    }
    if (linkCount < 3) {
      throw new Error(
        `Cannot initialize balloon; must have at least 3 links (linkCount = ${linkCount})`,
      );
    }
    if (totalMass <= 0) {
      throw new Error(
        `Cannot initialize balloon; must have positive mass (totalMass = ${totalMass})`,
      );
    }
    const individualMass = totalMass / linkCount;
    const masses = Array.from(Array(linkCount).keys())
      .map((index: number) => (index * 2 * Math.PI) / linkCount)
      .map((angle) =>
        new Vector2D(Math.cos(angle), Math.sin(angle))
          .Multiply(radius)
          .Add(centre),
      )
      .map((vertexPosition) => new PointMass(vertexPosition, individualMass));
    const springLength = (2 * Math.PI * radius) / linkCount;
    const springs = masses.map(
      (mass, index) =>
        new Spring(
          mass,
          masses[(index + 1) % masses.length],
          springLength,
          springConstant,
          dampingFactor,
        ),
    );

    const baseMassIndex = this.masses.length;
    for (let m = 0; m < masses.length; m += 1) {
      this.masses[baseMassIndex + m] = masses[m];
    }
    for (let s = 0; s < springs.length; s += 1) {
      this.AddConstraint(springs[s]);
    }
    this.AddConstraint(
      new Balloon(springs, Math.PI * radius * radius, pressureFactor),
    );
  }

  RandomizeMassPositions(magnitude: number, ignorePinned: boolean = true) {
    for (let m = this.masses.length - 1; m >= 0; m -= 1) {
      if (!ignorePinned || !this.masses[m].IsPinned()) {
        this.masses[m].SetPositionPreserveVelocity(
          this.masses[m].position.Add(
            new Vector2D(Math.random() - 0.5, Math.random() - 0.5).Multiply(
              magnitude,
            ),
          ),
        );
      }
    }
  }

  // Update
  UpdateSimulation() {
    if (!this.masses?.length) {
      return;
    }
    const g = new Vector2D(0, 9.81);
    for (let m = this.masses.length - 1; m >= 0; m -= 1) {
      this.masses[m].ApplyForce(g.Multiply(this.masses[m].mass));
      this.masses[m].Update(this.deltaTimeSqr);
      this.masses[m].ResolveBoundsCollisions(this.bounds);
    }
    // Resolve all constraints once
    for (let c = this.constraints.length - 1; c >= 0; c -= 1) {
      this.constraints[c].Resolve(this.deltaTimeReciprocal);
    }
    // Resolve all iterated constraints [iterationCount - 1] times
    for (let i = this.iterationCount - 1; i > 0; i -= 1) {
      for (let c = this.constraints.length - 1; c >= 0; c -= 1) {
        if (!this.constraints[c].forceBased) {
          this.constraints[c].Resolve(this.deltaTimeReciprocal);
        }
      }
      for (let c = this.circleMasses.length - 1; c >= 0; c -= 1) {
        for (let m = this.masses.length - 1; m >= 0; m -= 1) {
          if (this.circleMasses[c] !== this.masses[m]) {
            this.circleMasses[c].ResolveMassCollision(this.masses[m]);
          }
        }
      }
      if (
        this.selectedMassIndex >= 0 &&
        this.selectedMassIndex < this.masses.length
      ) {
        this.masses[this.selectedMassIndex].position =
          this.mousePosition.Copy();
      }
    }
    // Resolve bounds constraints last, to ensure they are respected the most
    for (let m = this.masses.length - 1; m >= 0; m -= 1) {
      this.masses[m].ResolveBoundsCollisions(this.bounds);
    }
  }

  // Drawing
  DrawSimulation() {
    super.DrawSimulation();
    if (!this.drawingContext) {
      return;
    }
    if (this.drawFunctions.length === 0) {
      this.drawFunctions = this.GetSortedDrawFunctions();
    }
    for (let d = this.drawFunctions.length - 1; d >= 0; d -= 1) {
      this.drawFunctions[d]();
    }
  }

  GetSortedDrawFunctions(): (() => void)[] {
    const functionsWithOrders: { order: number; function: () => void }[] = [];
    // Find all of the circles in the masses list for separate updating and drawing
    for (let m = 0; m < this.masses.length; m += 1) {
      functionsWithOrders[functionsWithOrders.length] = {
        order: 0,
        function: () => this.DrawPointMass(this.masses[m]),
      };
      if (this.masses[m] instanceof CircleMass) {
        const circleMass = this.masses[m] as CircleMass;
        this.circleMasses[this.circleMasses.length] = circleMass;
        functionsWithOrders[functionsWithOrders.length] = {
          order: 0,
          function: () => this.DrawCircleMass(circleMass),
        };
      }
    }
    // Designate drawing functions for all of the different constraints
    for (let c = 0; c < this.constraints.length; c += 1) {
      if (this.constraints[c] instanceof Rod) {
        functionsWithOrders[functionsWithOrders.length] = {
          order: 0,
          function: () =>
            this.DrawMassConnector(this.constraints[c] as MassConnector),
        };
      } else if (this.constraints[c] instanceof Spring) {
        functionsWithOrders[functionsWithOrders.length] = {
          order: 0,
          function: () => this.DrawSpring(this.constraints[c] as Spring),
        };
      } else if (this.constraints[c] instanceof Balloon) {
        functionsWithOrders[functionsWithOrders.length] = {
          order: 1,
          function: () => this.DrawBalloon(this.constraints[c] as Balloon),
        };
      }
    }
    // Order draw functions
    return functionsWithOrders
      .sort((a, b) => a.order - b.order)
      .map((entry) => entry.function);
  }

  DrawCursor() {
    if (!this.drawingContext || !this.mousePosition) {
      return;
    }
    super.DrawCursor();
    this.drawingContext.globalAlpha = 0.4;
    this.drawingContext.beginPath();
    this.drawingContext.arc(
      this.mousePosition.x,
      this.mousePosition.y,
      this.selectDistScaled,
      0,
      2 * Math.PI,
    );
    this.drawingContext.stroke();
    this.drawingContext.globalAlpha = 1;
  }

  DrawPointMass(mass: PointMass) {
    if (!this.drawingContext) {
      return;
    }
    this.drawingContext.strokeStyle = black;
    this.drawingContext.beginPath();
    // If not pinned
    if (mass.massStored === 0) {
      // Draw a circle
      this.drawingContext.arc(
        mass.position.x,
        mass.position.y,
        this.massDrawRadiusScaled,
        0,
        2 * Math.PI,
      );
      this.drawingContext.stroke();
    } else {
      // Draw a rectangle
      this.drawingContext.strokeRect(
        mass.position.x - this.massDrawRadiusScaled,
        mass.position.y - this.massDrawRadiusScaled,
        2 * this.massDrawRadiusScaled,
        2 * this.massDrawRadiusScaled,
      );
    }
  }

  DrawCircleMass(circleMass: CircleMass) {
    if (!this.drawingContext) {
      return;
    }
    this.drawingContext.strokeStyle = black;
    this.drawingContext.beginPath();
    // Draw a circle
    this.drawingContext.arc(
      circleMass.position.x,
      circleMass.position.y,
      circleMass.radius,
      0,
      2 * Math.PI,
    );
    this.drawingContext.stroke();
  }

  DrawMassConnector(massConnector: MassConnector) {
    if (!this.drawingContext) {
      return;
    }
    this.drawingContext.beginPath();
    this.drawingContext.moveTo(
      massConnector.massA.position.x,
      massConnector.massA.position.y,
    );
    this.drawingContext.lineTo(
      massConnector.massB.position.x,
      massConnector.massB.position.y,
    );
    this.drawingContext.stroke();
  }

  DrawSpring(spring: Spring) {
    if (!this.drawingContext) {
      return;
    }
    // Draw a normal mass connector
    this.DrawMassConnector(spring);
    // Make the line thicker and dashed
    const extraWidthFactor = 3;
    this.drawingContext.lineWidth *= extraWidthFactor;
    const oldLineDash = this.drawingContext.getLineDash();
    const lineDashSpacing = 0.0075 * (spring.Length() / spring.targetLength);
    this.drawingContext.setLineDash([lineDashSpacing * 0.5, lineDashSpacing]);
    // Draw the mass connector again with the modified line properties
    this.DrawMassConnector(spring);
    // Reset the line properties
    this.drawingContext.lineWidth /= extraWidthFactor;
    this.drawingContext.setLineDash(oldLineDash);
  }

  DrawBalloon(balloon: Balloon) {
    if (!this.drawingContext) {
      return;
    }
    const oldFillStyle = this.drawingContext.fillStyle;
    this.drawingContext.fillStyle = grey;
    this.drawingContext.beginPath();
    this.drawingContext.moveTo(
      balloon.edges[0].massA.position.x,
      balloon.edges[0].massA.position.y,
    );
    for (let e = 1; e < balloon.edges.length; e += 1) {
      this.drawingContext.lineTo(
        balloon.edges[e].massA.position.x,
        balloon.edges[e].massA.position.y,
      );
    }
    this.drawingContext.fill();
    this.drawingContext.fillStyle = oldFillStyle;
  }
}
