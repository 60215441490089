import styles from "./Sidebar.module.css";

function NavSidebar() {
  return (
    <div className={styles.sidebar}>
      <header>
        <div>Lewis \</div>
        <div>Antony</div>
        <div>Jones /</div>
      </header>
      <ul>
        <li>Work</li>
        <li>Projects</li>
        <li>Games</li>
        <li>About</li>
      </ul>
    </div>
  );
}

export default NavSidebar;
