export default [
  {
    id: "ea9b4ddf-3093-4e36-a9fe-fe712cc15376",
    title: "Mantle NFT",
    brief: "Made an NFT to display other NFTs",
    role: "Head of Software",
    company: "Purple Tambourine Ltd.",
    startDate: "2022-12-05T00:00:00.000Z",
    endDate: null,
    description: null,
    imageURL: null,
    videoURL: null,
    externalURLs: [
      "https://mantle.art/",
      "https://web.archive.org/web/20220223135855/https://mantle.art/",
    ],
    category: "Work",
  },
  {
    id: "0d185a8b-d26b-4a35-842a-d16cb48008d7",
    title: "Diorama iOS App",
    brief: "Made an AR video-creation app",
    role: "Head of Software",
    company: "LITHO",
    startDate: "2020-07-01T00:00:00.000Z",
    endDate: "2022-01-05T00:00:00.000Z",
    description: null,
    imageURL: null,
    videoURL: null,
    externalURLs: [
      "https://apps.apple.com/app/mantle-movie-maker/id1511733551",
      "https://apps.apple.com/gb/app/diorama-for-litho/id1632473962",
      "https://techcrunch.com/2019/02/18/litho/",
      "https://vrscout.com/news/ar-movie-making-diorama-digital-props-effects/",
      "https://technet-digital.co.uk/blog/the-litho-controller-brings-magic-to-vr-and-beyond/",
      "https://www.knoxlabs.com/products/litho",
    ],
    category: "Work",
  },
  {
    id: "3ab44906-03a8-4514-a597-f9d8ca433704",
    title: "Litho Hardware Interaction SDK",
    brief: "Made an SDK for the Litho controller",
    role: "Head of Software",
    company: "LITHO",
    startDate: "2019-07-01T00:00:00.000Z",
    endDate: "2020-01-01T00:00:00.000Z",
    description:
      "Made an SDK for integrating state-of-the-art Litho controller with Unity game engine",
    imageURL: null,
    videoURL:
      "https://www.youtube.com/watch?v=trZgLo83-uA&ab_channel=Diorama-byLitho",
    externalURLs: [
      "https://documentation.litho.cc/",
      "https://www.litho.cc/products/lithoandsdk",
      "https://www.youtube.com/watch?v=5cZp6kmvxyA&ab_channel=DilmerValecillos",
    ],
    category: "Work",
  },
  {
    id: "afa6324b-b413-4af5-8b65-1fecb3eea21e",
    title: "Litho Hardware Interaction Design",
    brief: "Developed interaction patterns for the Litho controller",
    role: "Head of Software",
    company: "LITHO",
    startDate: "2018-09-29T00:00:00.000Z",
    endDate: "2019-07-01T00:00:00.000Z",
    description: null,
    imageURL: null,
    videoURL:
      "https://www.youtube.com/watch?v=v8LeG3nco04&feature=emb_imp_woyt&ab_channel=Diorama-byLitho",
    externalURLs: ["https://www.litho.cc", "https://documentation.litho.cc/"],
    category: "Work",
  },
  {
    id: "4b337de2-c56e-4a09-83cc-87188e09f268",
    title: "Microsoft Research Agile Projects",
    brief: "Worked on data processing for machine learning projects",
    role: "Software Development Contractor",
    company: "Microsoft Research Cambridge",
    startDate: "2017-07-01T00:00:00.000Z",
    endDate: "2017-10-23T00:00:00.000Z",
    description: null,
    imageURL: null,
    videoURL: "https://www.youtube.com/watch?v=ZbaAH-gd-ro",
    externalURLs: [
      "https://www.microsoft.com/en-us/research/video/how-microsoft-and-novartis-created-assess-ms/",
    ],
    category: "Work",
  },
  {
    id: "eb005b36-67a2-4c18-b970-4caaf0a5e33e",
    title: "Assess MS Data Collection",
    brief: "Developed computer vision based data collection software",
    role: "Software Development Intern",
    company: "Microsoft Research Cambridge",
    startDate: "2016-07-18T00:00:00.000Z",
    endDate: "2016-09-16T00:00:00.000Z",
    description: null,
    imageURL: null,
    videoURL: "https://www.microsoft.com/en-us/research/video/assess-ms/",
    externalURLs: [
      "https://www.microsoft.com/en-us/research/project/assessing-multiple-sclerosis-with-kinect/",
      "https://www.microsoft.com/en-us/research/publication/assess-ms-supporting-the-clinical-assessment-of-multiple-sclerosis-using-kinect-2/",
    ],
    category: "Work",
  },
  {
    id: "564eb37f-8a12-4e90-94f6-e93cb652bfe3",
    title: "Gamers To Engineers",
    brief: "Developed engineering-related video games",
    role: "Game Designer",
    company: "Cambridge University Engineering Department",
    startDate: "2015-06-22T00:00:00.000Z",
    endDate: "2015-09-11T00:00:00.000Z",
    description:
      "Developed multiple video games promoting engineering thinking in GCSE-level students",
    imageURL: null,
    videoURL: "https://youtu.be/wvcH1-XpBx4?t=8",
    externalURLs: [
      "https://www.youtube.com/channel/UCh1BRWniVxGu_S_r1levN-Q",
      "https://i-want-to-study-engineering.org/",
    ],
    category: "Work",
  },
  {
    id: "c08409f4-7b4b-4445-9006-6e48ba2dfc67",
    title: "Networking Analysis",
    brief: "Developed data analysis visualizers for web",
    role: "Web Designer/ Data Analyst",
    company: "Blendology",
    startDate: "2014-06-30T00:00:00.000Z",
    endDate: "2014-08-29T00:00:00.000Z",
    description: null,
    imageURL: null,
    videoURL: null,
    externalURLs: ["https://www.blendology.com/"],
    category: "Work",
  },
  {
    id: "a8cced6a-ce01-4715-bce6-cbd29d33742e",
    title: "Radio Network Viewer",
    brief: "Developed a force-directed graph UX",
    role: "UX Developer",
    company: "Simulamen Ltd.",
    startDate: "2012-05-01T00:00:00.000Z",
    endDate: "2012-06-01T00:00:00.000Z",
    description: null,
    imageURL: null,
    videoURL: null,
    externalURLs: null,
    category: "Work",
  },
  {
    id: "2ee21564-dbd6-41b4-8c12-820624b64ea6",
    title: "Materialist",
    brief: "Solve puzzles using temperature and material properties",
    role: "Game Developer",
    company: "Cambridge University Engineering Department",
    startDate: "2015-06-22T00:00:00.000Z",
    endDate: "2015-09-11T00:00:00.000Z",
    description: null,
    imageURL: null,
    videoURL:
      "https://www.youtube.com/watch?v=8i44BZZsUF8&ab_channel=GamerstoEngineers",
    externalURLs: ["https://i-want-to-study-engineering.org/"],
    category: "Games",
  },
  {
    id: "e130ca68-64de-41f3-a57b-9d47d68b58f5",
    title: "Angular Momentor",
    brief: "Navigate a space station using angular momentum",
    role: "Game Developer",
    company: "Cambridge University Engineering Department",
    startDate: "2015-06-22T00:00:00.000Z",
    endDate: "2015-09-11T00:00:00.000Z",
    description: null,
    imageURL: null,
    videoURL:
      "https://www.youtube.com/watch?v=nFTUcS4Jfz8&ab_channel=GamerstoEngineers",
    externalURLs: ["https://i-want-to-study-engineering.org/"],
    category: "Games",
  },
  {
    id: "59953257-2910-408b-a0fa-565972c27a8b",
    title: "Something About Lasers",
    brief: "Manipulate high energy lasers with finesse",
    role: "Game Developer",
    company: "Cambridge University Engineering Department",
    startDate: "2015-06-22T00:00:00.000Z",
    endDate: "2015-09-11T00:00:00.000Z",
    description: null,
    imageURL: null,
    videoURL:
      "https://www.youtube.com/watch?v=LSlnciOLQ58&ab_channel=GamerstoEngineers",
    externalURLs: ["https://i-want-to-study-engineering.org/"],
    category: "Games",
  },
];
