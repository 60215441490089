//--------------------------------------------------
// 2D Vector implementation
// Copyright Lewis Jones 2023
//--------------------------------------------------

import { AngleToPlusMinusPiRange } from "./angles";

export default class Vector2D {
  constructor(public x: number, public y: number) {}

  Copy(): Vector2D {
    return new Vector2D(this.x, this.y);
  }

  Add(vector: Vector2D): Vector2D {
    return new Vector2D(this.x + vector.x, this.y + vector.y);
  }

  Sub(vector: Vector2D): Vector2D {
    return new Vector2D(this.x - vector.x, this.y - vector.y);
  }

  Multiply(number: number): Vector2D {
    return new Vector2D(this.x * number, this.y * number);
  }

  Divide(number: number): Vector2D {
    return this.Multiply(1 / number);
  }

  VectorMultiply(vector: Vector2D): Vector2D {
    return new Vector2D(this.x * vector.x, this.y * vector.y);
  }

  VectorDivide(vector: Vector2D): Vector2D {
    return new Vector2D(this.x / vector.x, this.y / vector.y);
  }

  Length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  LengthSqr(): number {
    return this.x * this.x + this.y * this.y;
  }

  Distance(vector: Vector2D): number {
    return Math.sqrt(this.DistanceSqr(vector));
  }

  DistanceSqr(vector: Vector2D): number {
    return this.Sub(vector).LengthSqr();
  }

  Dot(vector: Vector2D): number {
    return this.x * vector.x + this.y * vector.y;
  }

  Cross(vector: Vector2D): number {
    return this.x * vector.y - vector.x * this.y;
  }

  Angle(vector: Vector2D): number {
    return Math.acos(
      this.Dot(vector) / Math.sqrt(this.LengthSqr() * vector.LengthSqr()),
    );
  }

  SignedAngle(vector: Vector2D): number {
    const angle = Math.atan2(vector.y, vector.x) - Math.atan2(this.y, this.x);
    return AngleToPlusMinusPiRange(angle);
  }

  MinComponent(): number {
    return Math.min(this.x, this.y);
  }

  MaxComponent(): number {
    return Math.max(this.x, this.y);
  }

  // Note that this requires x+ to be right and y+ to be down (as in HTML layouts)
  PerpLeft(): Vector2D {
    return new Vector2D(this.y, -this.x);
  }

  // Note that this requires x+ to be right and y+ to be down (as in HTML layouts)
  PerpRight(): Vector2D {
    return new Vector2D(-this.y, this.x);
  }
}
