import styles from "./Sidebar.module.css";

function DetailPanel() {
  return (
    <div className={styles.sidebar}>
      <ul>
        <li>Product Manager</li>
        <li>Project Lead</li>
        <li>Games Designer</li>
        <li>Lead Developer</li>
      </ul>
    </div>
  );
}

export default DetailPanel;
