//--------------------------------------------------
// Base class for a connection constraint between two verlet point masses
// Copyright Lewis Jones 2023
//--------------------------------------------------

import { Vector2D } from "math";
import Constraint from "./Constraint";
import PointMass from "./PointMass";

export default class MassConnector extends Constraint {
  targetLengthSqr: number;
  constructor(
    readonly forceBased: boolean,
    readonly massA: PointMass,
    readonly massB: PointMass,
    readonly targetLength: number,
    readonly maxOnly: boolean = false,
  ) {
    if (massA === massB) {
      throw new Error(
        `Cannot initialize connector; cannot connect a mass to itself (mass = ${massA})`,
      );
    }
    if (targetLength <= 0) {
      throw new Error(
        `Cannot initialize connector; must have positive target length (targetLength = ${targetLength})`,
      );
    }
    super(forceBased);
    this.targetLengthSqr = targetLength * targetLength;
  }

  Resolve(deltaTimeReciprocal: number) {
    if (this.massA.massReciprocal + this.massB.massReciprocal > 0) {
      const relativePosition = this.SpanVector();
      const distanceSqr = this.LengthSqr();
      if (this.maxOnly && distanceSqr <= this.targetLengthSqr) {
        return;
      }
      const distance = Math.sqrt(distanceSqr);
      const normal = relativePosition.Divide(distance);
      // Correct the relative velocities
      let relativeVelocity = this.massA
        .GetVelocity(deltaTimeReciprocal)
        .Sub(this.massB.GetVelocity(deltaTimeReciprocal));
      relativeVelocity = normal.Multiply(relativeVelocity.Dot(normal));
      this.ResolveVelocity(relativeVelocity);
      // Correct the relative positions
      const distanceOffset = distance - this.targetLength;
      const positionOffset = normal.Multiply(distanceOffset);
      this.ResolveDistance(positionOffset);
    }
  }
  /* eslint-disable class-methods-use-this, @typescript-eslint/no-unused-vars */
  protected ResolveDistance(positionOffset: Vector2D) {}
  protected ResolveVelocity(relativeVelocity: Vector2D) {}
  /* eslint-enable class-methods-use-this, @typescript-eslint/no-unused-vars */

  SpanVector(): Vector2D {
    return this.massB.position.Sub(this.massA.position);
  }
  Length() {
    return Math.sqrt(this.LengthSqr());
  }
  LengthSqr() {
    return this.SpanVector().LengthSqr();
  }
}
