import Applet from "./Applet";
import Verlet2DPhysics from "./Verlet2DPhysics";

export type ConcreteApplet = (new (
  canvas: HTMLCanvasElement,
  deltaTime?: number,
) => Applet) & { [K in keyof typeof Applet]: typeof Applet[K] };

const appletSlugMapping: {
  [key: string]: { Applet: ConcreteApplet; title: string };
} = {
  "verlet-2d-physics": {
    Applet: Verlet2DPhysics,
    title: "Verlet 2D Physics",
  },
};

export { appletSlugMapping };
