import { NavLink } from "react-router-dom";
import { useDocumentTitle } from "usehooks-ts";
import { appletSlugMapping } from "./applets/AppletNameMappings";

function AppletDirectory() {
  useDocumentTitle("Lewis Antony Jones | Apps");

  return (
    <>
      {Object.keys(appletSlugMapping).map((objectKey) => (
        <NavLink key={objectKey} to={`./${objectKey}`}>
          {appletSlugMapping[objectKey].title}
        </NavLink>
      ))}
    </>
  );
}

export default AppletDirectory;
